
import React,{useState,useEffect} from 'react';
import {Link,useNavigate, useParams} from 'react-router-dom';
import axios from 'axios';

function Stores() {

  const [stores,setStores]=useState([]);
  //const {slug}=useParams("");
    
  const [cats,setCats]=useState([]);

  useEffect(()=>{
    window.scrollTo(0, 0);
    fetch('https://offerspick.com/admin/welcome/categoryapi').then((response)=>response.json())
    .then((responseJson)=>{
      setCats(responseJson);
    }).catch((error)=>{
      console.log(error);
    });
    var city= localStorage.getItem('city_id');
    axios.get('https://offerspick.com/admin/welcome/stores_api/'+city)
            .then((responseJson) => {
              console.log(responseJson.data);
              setStores(responseJson.data);
        }).catch((err) => alert("Data Loading Error"));
   
    }, []);

    const loadCoupons=()=>{

    }

    return (
      <>
         <div className="category-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="section-heading">All Stores</h2>
                    </div>
                </div>
                <div className="row">
                <div className="col-md-2">
                        <div className="cbx-sidebar">
                            <div className="widget widget-couponz-category-filter">

                                <ul>
                                  {cats.map(c=>
                                    <li>
                                        <Link to={"/stores/"+c.cat_slug}>
                                            <div className="category-thumb">
                                                <img src={'https://offerspick.com/admin/assets/img/'+c.cat_img} alt="" className="" />
                                            </div>

                                            <span>{c.cat_name}</span>
                                        </Link>
                                    </li>)}
                                </ul>

                            </div>

                        </div>
                    </div>
                    <div class="col-md-10">
                    <div class="blog-item-wrapper">
                        <div className='row'>
                        {stores.map(item=>
                                <div class="single-blog-item col-md-6" style={{background:'#fff',padding:10}}>
                                    <div class="row align-items-center g-5">
                                        <div class="col-md-5">
                                            <div class="blog-thumb">
                                                <Link to={"/store/"+item.shop_slug}>
                                                    <img src={'https://offerspick.com/admin/assets/img/'+item.shop_img} alt="Blog" class="img-responsive img-rounded" />
                                                </Link>
                                            </div>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="blog-content">
                                                <h4>
                                                <Link to={"/store/"+item.shop_slug}>{item.shop_name}</Link>
                                                </h4>
                                                <div class="blog-tags">
                                                    <ul>
                                                        <li class="blog-author">
                                                            <a href="#"><i class="fa fa-phone" aria-hidden="true"></i>{item.shop_phone}</a>
                                                        </li>
                                                        <li class="blog-date">
                                                            <a href="#"><i class="fa fa-list" aria-hidden="true"></i>{item.cat_name}</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <p>{item.shop_add}</p>
                                                <Link class="cbx-btn btn-sm readmore" to={"/store/"+item.shop_slug}>Visit Store</Link>
                                                
                                                <span className='badge bg-success float-end'>{item.total_offers} Offers</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>)}
                                </div>
                            </div> 
                    </div>
                </div>
            </div>
          </div>
      </>
    );
  }

export default Stores;
