
import React from "react";
import {HashRouter,Routes, Route} from 'react-router-dom';
import Coupons from "./components/frontend/Coupons";
import Frontend from './components/frontend/Frontend';
import Home from './components/frontend/Home';
import About from './components/frontend/About';
import Contact from "./components/frontend/Contact";
import Register from "./components/frontend/Register";
import Login from "./components/frontend/Login";
import Dashboard from "./components/frontend/Dashboard";
import CouponsList from "./components/frontend/CouponsList";
import Stores from "./components/frontend/Stores";
import StoreDetails from "./components/frontend/StoreDetails";
import Wishlist from "./components/frontend/Wishlist";
import AddStore from "./components/frontend/AddStore";
import Claimed from "./components/frontend/Claimed";
import Category from "./components/frontend/Category";
import QrShare from "./components/frontend/QrShare";
import Terms from "./components/frontend/Terms";
import Privacy from "./components/frontend/Privacy";
import StoreClaimed from "./components/frontend/StoreClaimed";

function App() {
  return (
    <HashRouter basename="/">
      <Routes>
        <Route path='/' element={<Frontend/>}>
          <Route index element={<Home/>}/>
          <Route path='/about' element={<About/>}/>
          <Route path='/login' element={<Login/>}/>
          <Route path='/register' element={<Register/>}/>
          <Route path='/contact' element={<Contact/>}/>
          <Route path='/coupons/:slug' element={<Coupons/>}/>
          <Route path='/stores/:slug?' element={<Stores/>}/>
          <Route path='/store/:slug' element={<StoreDetails/>}/>
          <Route path='/dashboard' element={<Dashboard/>}/>
          <Route path='/couponlist' element={<CouponsList/>}/>
          <Route path='/mywishlist' element={<Wishlist/>}/>
          <Route path='/claimed' element={<Claimed/>}/>
          <Route path='/claimed' element={<Claimed/>}/>
          <Route path='/addstore' element={<AddStore/>}/>
          <Route path='/category' element={<Category/>}/>
          <Route path='/terms' element={<Terms/>}/>
          <Route path='/privacy' element={<Privacy/>}/>
          <Route path='/getmyqr/:slug' element={<QrShare/>}/>
          <Route path='/storeclaimed' element={<StoreClaimed/>}/>
        </Route>
      </Routes> 
    </HashRouter>
  );
}

export default App;
