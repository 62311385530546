
import { Outlet,Link,useParams,useNavigate} from 'react-router-dom';
import React,{Component} from 'react';
import axios from 'axios';
import { useState,useEffect } from "react";
import { Helmet } from 'react-helmet';
import OtpInput from 'react-otp-input';

function Frontend(navigation) {
    
    const navigate = useNavigate();

    const [ulat,setUlat]=useState();
    const [ulong,setUlong]=useState();
    const [category,setCategory]=useState([]);
    const [cities,setCities]=useState([]);
    const [otp, setOtp] = useState('');
    const [inputField , setInputField] = useState({mobile:'',name:'',userotp:'',newuser:false,logincheck:false,subemail:''});

    

    const handleChange = (e) =>{
        const value = e.target.value;
        setInputField({
            ...inputField,
            [e.target.name]: value
        });
    }


    const setCity=(id,name)=>{
        localStorage.setItem('city_id',id);
        localStorage.setItem('city_name',name);
        window.location.reload(false);
    }


    const loginForm = (e) =>{e.preventDefault();
        axios.post('https://offerspick.com/admin/Welcome/ulogin_api', {mobile:inputField.mobile})
            .then((res) => {
                console.log(res.data);
                //if(res.data.status==1){
                    setInputField({
                        ...inputField,userotp: res.data.otp,newuser:res.data.newuser
                    });
                //}
        }).catch((err) => alert("Couldnot reach to server"));      
    }

    const subsForm = (e) =>{e.preventDefault();
        if(inputField.subemail==''){alert("Please Enter Valid Email");return false;}
        axios.post('https://offerspick.com/admin/Welcome/subscription_api', {email:inputField.subemail})
            .then((res) => {
                console.log(res.data);
                alert(res.data.msg);
                setInputField.subemail('');
        }).catch((err) => alert("Couldnot reach to server"));      
    }

    const otpSubmit = param => e => {e.preventDefault();   
        if(otp!=inputField.userotp){alert("Incorrect OTP");return false;}
        axios.post('https://offerspick.com/admin/Welcome/uverify_otp', {mobile:inputField.mobile,name:inputField.name})
            .then((res) => {
            //console.log(res.data);return false;   
            if(res.data.status===1){
                setInputField({logincheck:true})
                localStorage.setItem('token',1);
                localStorage.setItem('isLoggedIn',true);
                localStorage.setItem('user',true);
                localStorage.setItem('userid',res.data.userid);
                localStorage.setItem('name',res.data.name);
                //navigate("/mywishlist");
            }else{
                alert(res.data.msg);
            }
            //if(res.status==1){this.componentDidMount();}
        }).catch((err) => alert("Couldnot reach to server"));    
    }

    const logout2=()=>{
        //console.log('logout');return false;
        localStorage.clear();
        navigate("/login");
    }
    // const loginsuccess=()=>{
    //     navigate("/mywishlist");
    // }
    
    useEffect(()=>{
        window.scrollTo(0, 0);
        if(!localStorage.getItem('city_id')){
            //navigator.geolocation.getCurrentPosition(function(position) {
           //     setUlat(position.coords.latitude);
            //    setUlong(position.coords.longitude);
             // });
              localStorage.setItem('city_id',11);
              localStorage.setItem('city_name','Kochi');
        }
        fetch('https://offerspick.com/admin/welcome/categoryapi').then((response)=>response.json())
            .then((responseJson)=>{
              setCategory(responseJson);
            }).catch((error)=>{
              console.log(error);
            });
            fetch('https://offerspick.com/admin/welcome/cities_api').then((response)=>response.json())
            .then((responseJson)=>{
              setCities(responseJson);
            }).catch((error)=>{
              console.log(error);
            });
    },[]);
  return (
    <>
    
    <div id="main_content" className="main-content">
       <header className="site-header header-fixed" data-responsive-width="991">

<div className="header-topbar-wrapper">
    <div className="container-fluid">
        <div className="topbar-content">
            <div className="site-logo">
                <Link to="/" className="logo">
                    <img src="assets/images/logo/logo.png" alt="site logo" className="main-logo"/>
                </Link>
            </div>

            <div className="topbar-right">
                {/* <Link className="btn btn-lg btn-warning" to='/login'>
                    <span className="hidden-xs">Submit Coupons</span>
                </Link> */}
                {/* <a href="#showSearchBar" className="search-btn" data-bs-toggle="collapse" href="#showSearchBar" role="button" aria-expanded="false" aria-controls="showSearchBar">
                    <i className="fas fa-search"></i>
                </a> */}
                {localStorage.getItem('userid') &&
                <button onClick={()=>logout2()} className="btn btn-lg btn-danger" type="button">
                    Logout <i className="fa fa-sign-out"></i>
                </button>}&nbsp;&nbsp;
                    <button type="button" className="btn btn-primary btn-lg dropdown-toggle" data-bs-toggle="dropdown">
                    {localStorage.city_name?localStorage.city_name:'Select City'}
                    </button>
                    <ul className="dropdown-menu">
                    {cities.map(item=>
                        <li><a onClick={()=>setCity(item.citi_id,item.city_name)} className="dropdown-item" href="javascript: void(0)">{item.city_name}</a></li>)}
                    </ul>
                    </div>
        </div>
    </div>
</div>


<div className="container-fluid">
    <div className="header-inner">

        <nav id="site-navigation" className="main-nav">

            <div className="burger-menu">
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
            </div>

            <div className="menu-wrapper main-nav-container canvas-menu-wrapper" id="mega-menu-wrap">

                <div className="canvas-header">
                    <div className="mobile-offcanvas-logo">
                        <Link href="/">
                            <img src="assets/images/logo/logo.png" alt="site logo" className="logo-sticky"/>
                        </Link>
                    </div>

                    <div className="close-menu menu-close" id="page-close-main-menu">
                        <i className="fas fa-times"></i>
                    </div>

                </div>

                <ul className="codeboxr-main-menu">
                     <li><Link  id="page-close-main-menu" className="menu-close" to="/">Home</Link></li>
                     <li><Link  id="page-close-main-menu" className="menu-close" to="/coupons/new">New Offers</Link></li>
                     
                     <li className="has-submenu">
                     <Link id="page-close-main-menu" className="menu-close" to="/category">Category</Link> 
                     <ul className="sub-menu">
                         {category.map(x=>(



                    <li><Link  id="page-close-main-menu" to={'/coupons/'+x.cat_slug}>{x.cat_name}</Link></li> 
                                ))}

                                        </ul>
                                </li>
                                 <li>  <Link className="menu-close" id="page-close-main-menu" to="/stores">Store</Link> </li>
                   
               
                    <li>  <Link className="menu-close" id="page-close-main-menu" to="/about">About</Link> </li>
{/* 
                    <li>
                    <Link to="/blog">Blog</Link> 

                    </li> */}
                  
                    <li className="menu-item-depth-0"> <Link className="menu-close" id="page-close-main-menu" to="/contact">Contact</Link> </li>
                    
                </ul>
            </div>
            {localStorage.getItem('shop') && 
            <Link className="btn btn-primary btn-lg" to="/dashboard">My Shop</Link>}
            
            {!localStorage.getItem('shop') && !localStorage.getItem('user') &&
            <Link className="btn btn-warning btn-lg" to="/login">Shop Login</Link>}

            {!localStorage.getItem('user') && !localStorage.getItem('shop') &&
            <button className="btn btn-primary btn-lg" type='button' data-bs-toggle="modal" data-bs-target="#loginModal">Login</button>}
             {localStorage.getItem('user') &&
            <Link className="btn btn-warning btn-lg" to="/mywishlist">{localStorage.getItem('name')}</Link>}
        </nav>
    </div>
</div>
       </header>
       <div className="modal fade" id="loginModal" tabindex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-sm">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Login to Your Account</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body post-comment-form-group">
                {inputField.logincheck==false &&
                <form onSubmit={loginForm} method='post'>
                    <div className='form-group'>
                        <label>Mobile</label>
                        <input type="text" className="form-control" name="mobile" onChange={handleChange} required/>                              
                    </div>
                    {inputField.newuser==true &&
                    <div className='form-group'>
                        <label>Name</label>
                        <input type="text" className="form-control" name="name" onChange={handleChange} required/>                              
                    </div>}
                    {inputField.userotp!='' &&
    <div className='text-center'><br/>
        <h4 className='text-warning'>Verify Your OTP</h4><hr/>
    <OtpInput inputStyle={{width:'15%',height:'40px'}} containerStyle={{width:'100%'}}
      value={otp}
      onChange={setOtp}
      numInputs={6}
      renderSeparator={<span>-</span>}
      renderInput={(props) => <input {...props} />}
    /><br/>
    {otp.length==6 &&
       <button type='button' onClick={otpSubmit()} className="btn btn-lg btn-warning btn-block">VERIFY OTP</button>
        }
    </div>}
    {inputField.userotp=='' &&
                    <button type="submit" className="btn btn-primary btn-lg">Login</button>}
                    <button type="button" className="btn btn-secondary btn-lg float-end" data-bs-dismiss="modal">Close</button>
                </form>}
                {inputField.logincheck==true &&
                <div className='text-center'>
                <div className='alert alert-success'><i className='fa fa-check fa-3x'></i> Logged in successfully</div><hr/>
                <button onClick={()=>navigate("/mywishlist")} data-bs-dismiss="modal" className='btn btn-lg btn-primary'>Go to My Account</button>
                </div>}
            </div>
            </div>
        </div>
        </div>
        
       <Outlet/>
       <footer className="cbx-footer">
            <div className="cbx-footer-top">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4 col-sm-6 col-xs-12">
                            <div className="widget widget-about">
                                <div className="widget-brand">
                                    <a href="#">
                                        <img src="assets/images/logo/logo.png" alt="offerspick" className="img-responsive" />
                                    </a>
                                </div>
                                <p>We work with hundreds of trusted partners and merchants to bring you the most relevant
and updated deals and coupons every day. Whether you’re looking for a discount on your
favorite restaurant, a bargain on a new gadget, or a special offer on a dream vacation, we
have it all.</p>
                                <div className="widget-social">
                                    <ul>
                                        <li>
                                            <a href="https://www.facebook.com/offerspick/" target="_blank">
                                                <i className="fab fa-facebook-f"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/offerspick/" target="_blank">
                                                <i className="fab fa-instagram"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-2 col-sm-6 col-xs-12">
                            <div className="widget widget-listings">
                                <h2>Useful Link</h2>
                                <ul>
                                <li><Link to="/">Home</Link></li>
                     <li><Link to="/coupons/new">New Offers</Link></li>
                    
                  
                    <li> <Link to="/contact">Contact</Link> </li>
                    <li> <Link to="/about">About</Link> </li>
                                 <li>  <Link to="/stores">Stores</Link> </li>
                                 
                    <li>  <Link to="/terms">Terms & Conditions</Link> </li>
                    <li>  <Link to="/privacy">Privacy Policy</Link> </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-md-2 col-sm-6 col-xs-12">
                            <div className="widget widget-listings">
                                <h2>Categories</h2>
                                <ul>
                                {category.map(x=>(
                                <li><Link to={'/coupons/'+x.cat_slug}>{x.cat_name}</Link></li>))}
                                </ul>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-6 col-xs-12">
                            <div className="widget widget-newsletter">
                                <div className="widget-newsletter-area">
                                    <form onSubmit={subsForm} method='post' className="navbar-form" role="search">
                                        <div className="input-group">
                                            <input type="email" required onChange={handleChange} value={inputField.subemail} 
                                            className="form-control" placeholder="Your Email" name="subemail" />
                                            <div className="input-group-btn">
                                                <button className="btn btn-default" type="submit">
                                                    <i className="far fa-paper-plane"></i>
                                                </button>
                                            </div>
                                            <p id="cbx-subscribe-form-error"></p>
                                        </div>
                                    </form>
                                    <p>
                                        Subscribe for latest offers
                                    </p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cbx-footer-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="copyright text-center">
                                <p>
                                    © 2024 Copyright offerspick. All Rights reserved.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
    </>
  );
}

export default Frontend;
