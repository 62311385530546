
import React,{useState,useEffect} from 'react';
import {Link,useNavigate, useParams} from 'react-router-dom';
import axios from 'axios';

function Coupons() {

  const [offers,setOffers]=useState([]);
  const {slug}=useParams("slug");
  const [cqr,setCqr]=useState();
  const [spinner,setSpinner]=useState(true);
    
  const [cats,setCats]=useState([]);

  useEffect(()=>{
    window.scrollTo(0, 0);
    fetch('https://offerspick.com/admin/welcome/categoryapi').then((response)=>response.json())
    .then((responseJson)=>{
      setCats(responseJson);
    }).catch((error)=>{
      console.log(error);
    });
    var city= localStorage.getItem('city_id');
    console.log('https://offerspick.com/admin/welcome/offers_api/'+city+'/'+slug);
    axios.get('https://offerspick.com/admin/welcome/offers_api/'+city+'/'+slug)
            .then((responseJson) => {
              console.log(responseJson.data);
              setOffers(responseJson.data);
              setSpinner(false)       
        }).catch((err) => alert("Data Loading Error"));
   
    }, [slug]);

    const loadCoupons=()=>{

    }
    
    const generateQr=(id)=>{
      if(!localStorage.getItem('userid')){return false;}
        var userid= localStorage.getItem('userid');
        axios.post('https://offerspick.com/admin/Welcome/generate_qr/', {cid:id,user:userid})
            .then((res) => {
              
              setCqr(res.data.qrimg);
        }).catch((err) => console.log("Couldnot reach to server"));   
    }

    return (
      <>
         <div className="category-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="section-heading">All categories</h2>
                    </div>
                </div>
                <div className="row">
                <div className="col-md-2">
                        <div className="cbx-sidebar">
                            <div className="widget widget-couponz-category-filter">

                                <ul>
                                  {cats.map(c=>
                                    <li>
                                        <Link to={"/coupons/"+c.cat_slug}>
                                            <div className="category-thumb">
                                                <img src="assets/images/coupon-category/001-smartphone.png" alt="" className="" />
                                            </div>

                                            <span>{c.cat_name}</span>
                                        </Link>
                                    </li>)}
                                </ul>

                            </div>

                        </div>
                    </div>
                    <div class="col-md-10">
                        <div class="row">
                            {offers.map(item=>
                            <div class="col-md-3 col-sm-6 col-xs-12">
                                <div  className="item deal-item">
                    <div  className="deal-thumb">
                        <img src={'https://offerspick.com/admin/assets/img/'+item.offer_img} alt=""  className="img-responsive" />
                        <div  className="deal-badge">
                           
                        {item.offer_type=='Amount' && '₹'+item.offer_discount+' off'}
                                        {item.offer_type!='Amount' && item.offer_discount+'% off'}
                        </div>

                    </div>
                    <div className="deal-content">
                          <h6><a href="#">{item.offer_title} </a></h6>
                          {item.offer_type=='Amount' &&
                          <p><b className='text-danger'>₹{item.offer_discount}</b> off {item.offer_mino!='' && ' Min Order ₹'+item.offer_mino}</p>}
                          {item.offer_type!='Amount' &&
                          <p><b className='text-danger'>{item.offer_discount}%</b> off {item.offer_maxd!='' && ' upto ₹'+item.offer_maxd} {item.offer_mino!='' && ' Min Order ₹'+item.offer_mino}</p>}
                          <small><b className='text-primary'>{item.shop_name}</b>
                          <span className='badge bg-success float-end'>{item.cat_name}</span><br/>
                          <i className="fa fa-map-marker"></i> {item.shop_add}</small>
                          <div className="deal-content-bottom">
                              <p className='text-dark'><i className="fa fa-tag"></i> {item.total_claimed} Claimed</p>
                              <a onClick={()=>generateQr(item.offer_id)} data-bs-toggle="modal" data-bs-target={!localStorage.getItem('user')?'#loginModal':'#qrModal'}
                              href="javascript: void(0)" className="btn btn-lg">GET QR</a>
                          </div>
                      </div>
                </div>
                            </div>)}
                          </div>
                        </div>
                </div>
            </div>
          </div>
          
<div className="modal fade in"  id="qrModal" tabindex="-1" aria-labelledby="qrModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-sm">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="qrModalLabel">OFFER QR CODE</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body post-comment-form-group">
            <img src={cqr} alt=""  className="img-responsive" />
            </div>
            </div>
        </div>
        </div>
      </>
    );
  }

export default Coupons;
