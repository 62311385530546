
import React,{useState,useEffect} from 'react';
import {Link,useNavigate, useParams} from 'react-router-dom';
import axios from 'axios';

function StoreDetails() {

  const [offers,setOffers]=useState([]);
  const {slug}=useParams("slug");
  const [cqr,setCqr]=useState();
  const [storedetails ,setStoredetais] = useState({shop_name:'',shop_img:'',shop_cat:'',shop_add:'',shop_phone:''});
    
  useEffect(()=>{
    axios.get('https://offerspick.com/admin/welcome/store_details_api/'+slug)
    .then((responseJson) => {
        console.log(responseJson.data);
        setStoredetais({shop_name:responseJson.data.shop_name,shop_img:responseJson.data.shop_img,shop_add:responseJson.data.shop_add,
            shop_phone:responseJson.data.shop_phone,shop_cat:responseJson.data.cat_name,shop_name:responseJson.data.shop_name});
    }).catch((err) => alert("Data Loading Error"));
    
    axios.get('https://offerspick.com/admin/welcome/store_offers_api/'+slug)
            .then((responseJson) => {
              console.log(responseJson.data);
              setOffers(responseJson.data);
        }).catch((err) => alert("Data Loading Error"));
    }, [slug]);

    const loadCoupons=()=>{

    }

    const generateQr=(id)=>{
        if(!localStorage.getItem('userid')){return false;}
        var userid= localStorage.getItem('userid');
        axios.post('https://offerspick.com/admin/Welcome/generate_qr/', {cid:id,user:userid})
            .then((res) => {
                
               setCqr(res.data.qrimg);
        }).catch((err) => console.log("Couldnot reach to server"));   
    }

    return (
      <>
          <div className="single-store-wrapper">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-md-2 col-xs-12">
                        <div className="single-store-thumb">
                            <img src={'https://offerspick.com/admin/assets/img/'+storedetails.shop_img} alt="Store" className="img-responsive" />
                        </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                        <div className="single-store-content">
                            <h5>{storedetails.shop_name}</h5>
                            <p>{storedetails.shop_add}</p>
                            <p><span className='text-primary'>
                            <i className="fa fa-phone"></i> {storedetails.shop_phone}</span>&nbsp;&nbsp;
                                <span className='text-info'><i className="fa fa-list"></i> {storedetails.shop_cat}</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div className="category-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="section-heading">All categories</h2>
                    </div>
                </div>
                <div className="row">
                {offers.map(item=>
                            <div class="col-md-3 col-sm-6 col-xs-12">
                                <div class="item deal-item">
                                    <div class="deal-thumb">
                                        <img src={'https://offerspick.com/admin/assets/img/'+item.offer_img} alt="" class="img-responsive" />
                                        <div class="deal-badge">
                                            87%
                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" aria-valuenow="45" aria-valuemin="0" aria-valuemax="100">
                                                <span class="popOver" data-toggle="tooltip" data-placement="top" title="45%">
                                                </span>
                                            </div>
                                        </div>

                                    </div>
                      <div class="deal-content">
                          <h6><a href="#">{item.offer_title} </a></h6>
                          {item.offer_type=='Amount' &&
                          <p>Get <b class='text-danger'>₹{item.offer_discount}</b> off {item.offer_mino!='' && ' on order above ₹'+item.offer_mino}</p>}
                          {item.offer_type!='Amount' &&
                          <p>Get <b class='text-danger'>{item.offer_discount}%</b> off {item.offer_maxd!='' && ' upto ₹'+item.offer_maxd} {item.offer_mino!='' && ' on order above ₹'+item.offer_mino}</p>}
                          <div className="deal-content-bottom">
                              <p className='text-dark'><i className="fa fa-tag"></i> {item.total_claimed} Claimed</p>
                              <a onClick={()=>generateQr(item.offer_id)} data-bs-toggle="modal" data-bs-target={!localStorage.getItem('user')?'#loginModal':'#qrModal'}
                              href="javascript: void(0)" className="btn btn-lg">GET QR</a>
                          </div>
                      </div>
                                </div>
                            </div>)}
                </div>
            </div>
        </div>
        
<div className="modal fade in"  id="qrModal" tabindex="-1" aria-labelledby="qrModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-sm">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="qrModalLabel">OFFER QR CODE</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body post-comment-form-group">
            <img src={cqr} alt=""  className="img-responsive" />
            </div>
            </div>
        </div>
        </div>
      </>
    );
  }

export default StoreDetails;
