
import React,{Component} from 'react';
import axios from 'axios';
import { useState,useEffect } from "react";
import {Link} from 'react-router-dom';

function CouponsList() {

    const [offers,setOffers]=useState([]);
    const [qrimg,setQrimg]=useState();

    

    useEffect(() => {
        var userid= localStorage.getItem('userid');
        fetch('https://offerspick.com/admin/welcome/claimed_api/'+userid).then((response)=>response.json())
            .then((responseJson)=>{
                console.log(responseJson)
                setOffers(responseJson);
            }).catch((error)=>{
              console.log(error);
            });
      }, []);

    return (
      <>
      <section className="dashboard-wrapper single-coupon-wrapper">
            <div className="container-fluid">
            <div className="row">
                    <div className="col-md-12 no-padding-left-right">
                        <div className="dashboard-title text-center">
                            <h6>Hi, {localStorage.getItem('name')}</h6>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <div className="dashboard-nav">
                            <ul>
                                <li><Link to="/dashboard"><i className='fa fa-shop'></i> My Shops</Link></li>
                                <li><Link to="/addstore"><i className='fa fa-building'></i> Add Shop</Link></li>
                                <li><Link to="/couponlist"><i className='fa fa-tag'></i> Offers</Link></li>
                                <li className="active"><Link to="/storeclaimed"><i className='fa fa-list'></i> Claimed Offers</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-9">
                    <div class="dashboard-wrap">
                            <table class="table table-responsive">
                                <thead>
                                    <tr>
                                        <th>S.No.</th>
                                        <th>Shop</th>
                                        <th>User</th>
                                        <th>Offer</th>
                                        <th>Bill</th>
                                        <th>Discount</th>
                                        <th>Total</th>
                                        <th>Date</th>
                                        <th>Item</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {offers.map((item, index) => (
                                    <tr>
                                        <th scope="row">{index+1}</th>
                                        <td>{item.shop_name}<br/>
                          <span className='badge bg-success'>{item.cat_name}</span></td>
                          
                          <td>{item.user_name}<br/>
                          <span className='badge bg-success'>{item.user_mobile}</span></td>
                                        <td>{item.offer_title}</td>
                                        <td className='text-warning'>{item.wc_total}</td>
                                        <td className='text-success'>-{item.wc_disc}</td>
                                        <td className='text-primary'>{item.wc_paid}</td>
                                        <td>{item.wc_claim_date}</td>
                                        <td>{item.wc_items}</td>
                                    </tr>))}
                                </tbody>
                            </table>
                        </div>
                          </div>
                </div>
            </div>
        </section>
      </>
    );
  }
  
  export default CouponsList;


  