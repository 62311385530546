import { Link,useNavigate} from 'react-router-dom';
import React,{Component} from 'react';
import axios from 'axios';
import { useState,useEffect} from"react";

function Contact(navigation) {
    const navigate = useNavigate();

    const [cbxname,setCbxname]=useState([]);

    const [cbxemail,setCbxemail]=useState('');
    const [cbxphone,setCbxphone]=useState('');
    const [cbxsubject,setCbxsubject]=useState('');
    const [cbxmessage,setCbxmessage]=useState('');
    const [csubmit, setCsubmit] = useState(0)

    const cbxnamechange=(e)=>{
        setCbxname(e.target.value);
    }
    const cbxemailchange=(e)=>{
        setCbxemail(e.target.value);
    }
    const cbxphonechange=(e)=>{
        setCbxphone(e.target.value);
    }

    const cbxsubjectchange=(e)=>{
        setCbxsubject(e.target.value);
    }

    const cbxmessagechange=(e)=>{
        setCbxmessage(e.target.value);
    }

    
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const handleSubmit = (e) =>{e.preventDefault();
        axios.post('https://offerspick.com/admin/Welcome/contact_api2', {enq_name:cbxname,enq_phone:cbxphone,
        enq_email:cbxemail,enq_subject:cbxsubject,enq_msg:cbxmessage})
            .then((res) => {
                //console.log(res.data);return false;
                setCsubmit(res.data.status);return false;
                alert(res.data.msg);return false;
            //
            
            if(res.data.status===1){
                localStorage.setItem('token',1);
                localStorage.setItem('isLoggedIn',true);
                localStorage.setItem('shop',true);
                localStorage.setItem('userid',res.data.userid);
                localStorage.setItem('name',res.data.name);
                navigate("/dashboard");
            }else{
                alert(res.data.msg);
            }
            //if(res.status==1){this.componentDidMount();}
        }).catch((err) => alert("Couldnot reach to server"));    
      }

    return (
      <div>
      <div className="contact-wrapper">
            <div className="contact-wrapper-inner">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="contact-form-wrapper">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="contact-title">
                                            <h6>Contact Us</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-7">
                                        <div className="post-comment-form-group">
                                        {csubmit==0 &&
                                        <form method='post'  onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <input type="text" name="cbxname" onChange={cbxnamechange} placeholder="Name*" className="form-control" required="" aria-required="true"/>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <input type="email" name="cbxemail" onChange={cbxemailchange}  placeholder="Email*" className="form-control" required="" aria-required="true"/>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <input type="text" name="cbxphone" onChange={cbxphonechange}  
                                                        placeholder="Phone*" className="form-control" required=""
                                                         aria-required="true"/>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <input type="text" name="cbxsubject" onChange={cbxsubjectchange}  placeholder="Subject" className="form-control"/>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <textarea cols="30" name="cbxmessage" onChange={cbxmessagechange}  rows="6" placeholder="Message*" className="form-control" required=""></textarea>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <input type="submit" value="Send Your Message" className="btn btn-block btn-brand btn-lg"/>
                                                    </div>
                                                    <div id="cbx-formalert"></div>
                                                </div>
                                            </form>}
                                            {csubmit==1 &&
                                            <div className='alert alert-success'>Your Enquiry Submitted Successfully.
                                            <br/>We will get back to you soon</div>}
                                            {csubmit==2 &&
                                            <div className='alert alert-danger'>Couldnot receive your Enquiry. Please try later</div>}
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="contact-hotline">
                                            <div className="hotline-content">
                                                <p>HELPLINE</p>
                                                <p>09516033333</p>
                                            </div>
                                            <div className="hotline-thumb">
                                                <img src="assets/images/contact/mr_helpline.jpg" alt="" className="img-responsive img-rounded" />
                                            </div>
                                        </div>

                                        <div className="contact-info-wrapper">
                                            {/* <h6>Address:</h6>
                                            <p>Apartment 6H, Dilara Tower, Bir Uttam C.R. Dutta Road, Dhaka 1205, Bangladesh</p> */}
                                            <h6>Contact Info:</h6>
                                            <p><i className="fa fa-phone"></i>09516033333 </p>
                                            <p><i className="fa fa-envelope"></i>help@offerspick.com, <i className="fa fa-globe"></i>www.offerspick.com</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
   

    
      </div>
    );
  }
  
  export default Contact;


  