
import { useState,useEffect} from"react";

function About() {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
      <div>
    <section className="get-to-know-us-wrapper">
            <div className="container-fluid">
                <div className="row align-items-center g-5">
                    <div className="col-md-5">
                        <div className="get-to-know-us-video">
                            <img src="assets/images/video/video_bg.jpg" alt="Video" className="img-responsive img-rounded" />
                            <a href="https://www.youtube.com/watch?v=9No-FiEInLA" data-lity><i className="fa fa-play"></i></a>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className="get-to-know-us-content">
                            <h4>Vision and mission statement</h4>
                            <p>
                            Our vision is to become the ultimate destination for finding the best deals and coupons on everything you need, from groceries and electronics to travel and entertainment.

                            </p>
                            <p>
                                
Our mission is to help you save money and time by aggregating and providing all the best offers and coupons from multiple sources in one place. We also help businesses grow by connecting them with new and loyal customers, and providing them with insights and analytics on their offers.
                            </p>
                            {/* <a href="#" className="btn cbx-btn">Read More</a> */}

                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="get-to-know-us-content">
                    <h2 className="section-heading">About Us</h2>
                        <p>We are a team of passionate and experienced professionals who love finding and sharing the best deals and coupons with our users. We started our website and app in 2023 with the aim of creating a simple and convenient way for people to discover and redeem the best offers and coupons from various categories and locations.
</p><p>
We work with hundreds of trusted partners and merchants to bring you the most relevant and updated deals and coupons every day. Whether you’re looking for a discount on your favorite restaurant, a bargain on a new gadget, or a special offer on a dream vacation, we have it all.
</p><p>
We also care about our partners and merchants, and we strive to help them grow their businesses and reach new customers. We offer them a platform to showcase their offers and coupons, and we provide them with tools and data to track and optimize their performance.
</p><p>
We are always looking for feedback and suggestions from our users and partners, and we constantly improve our website and app to make them more user-friendly and feature-rich. We hope you enjoy using our website and app as much as we enjoy creating them for you.</p></div>
                    </div>
                </div>
            </div>
        </section>


    
        <section className="how-we-works-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xs-12">
                        <h2 className="section-heading">How We Works</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="single-how-we-works text-center">
                            <div className="how-we-works-thumb">
                                <img src="assets/images/how-we-works/coupon.png" alt="How We Works" className="img-responsive" />
                            </div>
                            <h6>Get Discount By Coupon</h6>
                            <p>You can get ma many discount wi coupon code apply of varius typeweb</p>
                            <a href="#" className="cbx-btn btn-sm">Get This</a>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="single-how-we-works text-center">
                            <div className="how-we-works-thumb">
                                <img src="assets/images/how-we-works/deal.png" alt="How We Works" className="img-responsive" />
                            </div>
                            <h6>Get Discount By Deal</h6>
                            <p>You can get ma many discount wi coupon code apply of varius typeweb</p>
                            <a href="#" className="cbx-btn btn-sm">Get This</a>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="single-how-we-works text-center">
                            <div className="how-we-works-thumb">
                                <img src="assets/images/how-we-works/voucher.png" alt="How We Works" className="img-responsive" />
                            </div>
                            <h6>Gift Cash Voucher</h6>
                            <p>You can get ma many discount wi coupon code apply of varius typeweb</p>
                            <a href="#" className="cbx-btn btn-sm">Get This</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

  
      

    
      </div>
    );
  }
  
  export default About;


  