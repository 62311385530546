
import React,{Component} from 'react';
import axios from 'axios';
import { useState,useEffect } from "react";
import {Link} from 'react-router-dom';

function CouponsList() {

    const [coupons,setCoupons]=useState([]);
    const [coupon_img,setCoupon_img]=useState();
    const [inputField , setInputField] = useState({max_use:'Once',
coupon_title:'',coupon_desc:'',coupon_code:'',disc_type:'Amount',dis_amt:'',min_order:'',max_disc:'',coupon_exp:''});

    const handleChange = (e) =>{
        const value = e.target.value;
        setInputField({
            ...inputField,
            [e.target.name]: value
        });
    }

    const handleimg2=(e)=>{
        console.log(e.target.files[0]);
        setCoupon_img(e.target.files[0]);
    }

    const loadCoupons=(type)=>{
        var userid= localStorage.getItem('userid');
        fetch('https://offerspick.com/admin/Welcome/user_coupons_api/'+userid+'/'+type).then((response)=>response.json())
        .then((responseJson)=>{
            console.log(responseJson);
            setCoupons(responseJson);
        }).catch((error)=>{
            console.log(error)
        });
    }
    useEffect(() => {
        loadCoupons('new')        
      }, []);

    const updateStatus=(id)=>{

    }


    const deleteOffer=(id)=>{
        
    }

    const csubmitForm=(e)=>{e.preventDefault();
        var userid= localStorage.getItem('userid');
        var formData = new FormData();
        //alert(coupon_img)
        formData.append("coupon_title", inputField.coupon_title);
        formData.append("coupon_code", inputField.coupon_code);
        formData.append("coupon_desc", inputField.coupon_desc);
        formData.append("coupon_exp", inputField.coupon_exp);
        formData.append("max_disc", inputField.max_disc);
        formData.append("max_use", inputField.max_use);
        formData.append("min_order", inputField.min_order);
        formData.append("disc_type", inputField.disc_type);
        formData.append("dis_amt", inputField.dis_amt);
        formData.append("coupon_img",coupon_img);
        const config = {
            headers: {
              'content-type': 'multipart/form-data',
            },
          };
      
        axios.post('https://offerspick.com/admin/Welcome/add_coupon_api', formData,config)
            .then((res) => {
            console.log(res.data);
            //if(res.data.status===1){useEffect();}
        }).catch((err) => alert("File Upload Error"));    
    }

    return (
      <>
      <section className="dashboard-wrapper single-coupon-wrapper">
            <div className="container-fluid">
            <div className="row">
                    <div className="col-md-12 no-padding-left-right">
                        <div className="dashboard-title text-center">
                            <h6>Hi, {localStorage.getItem('name')}</h6>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <div className="dashboard-nav">
                            <ul>
                                <li><Link to="/dashboard"><i className='fa fa-shop'></i> My Shops</Link></li>
                                <li><Link to="/addstore"><i className='fa fa-building'></i> Add Shop</Link></li>
                                <li className="active"><Link to="/couponlist"><i className='fa fa-tag'></i> Offers</Link></li>
                                <li><Link to="/storeclaimed"><i className='fa fa-list'></i> Claimed Offers</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-9">
                    <div class="dashboard-wrap">
                            <div class="dashboard-table-attr-type">
                                <ul>
                                    <li class="active"><a onClick={()=>loadCoupons('new')}>New</a></li>
                                    <li><a onClick={()=>loadCoupons('expired')}>Expired</a></li>
                                    {/* <li><a>All</a></li> */}
                                </ul>
                            </div>

                            <table class="table table-responsive">
                                <thead>
                                    <tr>
                                        <th>S.No.</th>
                                        <th>Title</th>
                                        <th>Discount</th>
                                        <th>Total Claimed</th>
                                        <th>Exp Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {coupons.map((item, index) => (
                                    <tr>
                                        <th scope="row">{index+1}</th>
                                        <td>{item.offer_title}<br/>
                                        <span className='badge bg-success'>{item.shop_name}</span></td>
                                        <td>
                                        {item.offer_type=='Amount' &&
                          <p><b className='text-danger'>₹{item.offer_discount}</b> off {item.offer_mino!='' && ' Min Order ₹'+item.offer_mino}</p>}
                          {item.offer_type!='Amount' &&
                          <p><b className='text-danger'>{item.offer_discount}%</b> off {item.offer_maxd!='' && ' upto ₹'+item.offer_maxd} {item.offer_mino!='' && ' Min Order ₹'+item.offer_mino}</p>}</td>
                                        <td>{item.total_claimed}</td>
                                        <td>{item.offer_exp}</td>
                                        {/* <td>
                                            <div class="rating">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="far fa-star"></i>
                                            </div>
                                        </td> */}
                                        <td>
                                        <a onClick={()=>updateStatus(item.offer_id)} 
                              href="javascript: void(0)" className="btn  btn-warning">{item.offer_status}</a>
                              
                              <a onClick={()=>deleteOffer(item.offer_id)} 
                              href="javascript: void(0)" className="btn  btn-danger"><i className='fa fa-trash'></i></a>
                                        </td>
                                    </tr>))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      </>
    );
  }
  
  export default CouponsList;


  