
import React,{Component} from 'react';
import axios from 'axios';
import { useState,useEffect } from "react";
import {Link} from 'react-router-dom';

function Dashboard() {

    const [shops,setShops]=useState([]);
    const [category,setCategory]=useState([]);
    const [shopform,setShopform]=useState(false);
    const [shop_img, setShop_img] = useState();
    const [coupon_img,setCoupon_img]=useState();
    const [shopid,setShopid]=useState();
    const [inputField , setInputField] = useState({shop_name:'',shop_phone:'',shop_cat:'',shop_add:'',shop_city:'',max_use:'Once',
coupon_title:'',coupon_desc:'',coupon_code:'',disc_type:'Amount',dis_amt:'',min_order:'',max_disc:'',coupon_exp:'',shop_lat:'',shop_long:''});

    const handleChange = (e) =>{
        const value = e.target.value;
        setInputField({
            ...inputField,
            [e.target.name]: value
        });
    }

    const handleimg=(e)=>{
        console.log(e.target.files[0]);
        setShop_img(e.target.files[0]);
    }
    const handleimg2=(e)=>{
        console.log(e.target.files[0]);
        setCoupon_img(e.target.files[0]);
    }

    const showform=()=>{
        setShopform(true);
    }

    useEffect(() => {
        fetch('https://offerspick.com/admin/welcome/categoryapi').then((response)=>response.json())
            .then((responseJson)=>{
              setCategory(responseJson);
            }).catch((error)=>{
              console.log(error);
            });
        var userid= localStorage.getItem('userid');
        fetch('https://offerspick.com/admin/Welcome/store_dashboard_api/'+userid).then((response)=>response.json())
        .then((responseJson)=>{
            setShops(responseJson.stores);
        }).catch((error)=>{
            console.log(error)
        });
      }, []);

      const submitForm=(e)=>{e.preventDefault();
        var userid= localStorage.getItem('userid');
        var formData = new FormData();
        //alert(inputField.shop_img)
        formData.append("shop_name", inputField.shop_name);
        formData.append("shop_cat", inputField.shop_cat);
        formData.append("shop_add", inputField.shop_add);
        formData.append("shop_img", shop_img);
        formData.append("shop_user", userid);
        formData.append("shop_phone",inputField.shop_phone);
        formData.append("shop_lat",inputField.shop_lat);
        formData.append("shop_long",inputField.shop_long);
        const config = {
            headers: {
              'content-type': 'multipart/form-data',
            },
          };
      
        axios.post('https://offerspick.com/admin/Welcome/add_shop_api', formData,config)
            .then((res) => {
            console.log(res.data);
            alert(res.data.msg);
            //if(res.data.status==1){
                
           // }
        }).catch((err) => alert("File Upload Error"));    
    }

    const csubmitForm=(e)=>{e.preventDefault();
        var userid= localStorage.getItem('userid');
        var formData = new FormData();
        //alert(coupon_img)
        formData.append("coupon_title", inputField.coupon_title);
        formData.append("coupon_code", inputField.coupon_code);
        formData.append("coupon_desc", inputField.coupon_desc);
        formData.append("coupon_exp", inputField.coupon_exp);
        formData.append("max_disc", inputField.max_disc);
        formData.append("max_use", inputField.max_use);
        formData.append("min_order", inputField.min_order);
        formData.append("disc_type", inputField.disc_type);
        formData.append("dis_amt", inputField.dis_amt);
        formData.append("shopid", shopid);
        formData.append("coupon_img",coupon_img);
        const config = {
            headers: {
              'content-type': 'multipart/form-data',
            },
          };
      
        axios.post('https://offerspick.com/admin/Welcome/add_coupon_api', formData,config)
            .then((res) => {
            console.log(res.data);
            alert(res.data.msg);
            //if(res.data.status===1){useEffect();}
        }).catch((err) => alert("File Upload Error"));    
    }

    const handleaddress=(e)=>{
        var vv = e.target.value;
        setInputField({
            ...inputField,
            [e.target.name]: vv
        });
        if(vv.length>20){
        axios.get('https://maps.google.com/maps/api/geocode/json?address='+vv+'&sensor=false&key=AIzaSyB-kcahCAxTprH5IcdCdfqRWFZOphPvlSo')
            .then((res) => {
           console.log(res.data.results[0].geometry.location.lng);
            setInputField({
                ...inputField,
                shop_lat:res.data.results[0].geometry.location.lat,
                shop_long:res.data.results[0].geometry.location.lng
            });
            //alert(res.data.msg);
        }).catch((err) => console.log("File Upload Error"));  
        } 
    }

    return (
      <>
      <section className="dashboard-wrapper single-coupon-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 no-padding-left-right">
                        <div className="dashboard-title text-center">
                            <h6>Hi, {localStorage.getItem('name')}</h6>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <div className="dashboard-nav">
                            <ul>
                                <li className="active"><Link to="/dashboard"><i className='fa fa-shop'></i> My Shops</Link></li>
                                <li><Link to="/addstore"><i className='fa fa-building'></i> Add Shop</Link></li>
                                <li><Link to="/couponlist"><i className='fa fa-tag'></i> Offers</Link></li>
                                <li><Link to="/storeclaimed"><i className='fa fa-list'></i> Claimed Offers</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="dashboard-wrap" style={{padding:"20px"}}>
                           <div className="post-comment-form-group">
                           
                            {shops.map(shop=>
                            <div className="row align-items-center g-5 mt-10">
                    <div className="col-md-3 col-sm-12">
                        <div className="single-coupon-thumb text-center">
                            <img src={"https://offerspick.com/admin/assets/img/"+shop.shop_img} alt="Coupon" 
                            className="img-responsive"/>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="single-coupon-content">
                            <h5>{shop.shop_name}</h5>
                            <p>{shop.shop_add}</p>
                            <p className="expiring-time">
                                <span className="btn btn-warning">{shop.cat_name}</span>
                                <i className="fa fa-tag-o"></i> {shop.total_offers} Coupons</p>

                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12 px-5">
                        <div className="single-coupon-button">
                            <a className="btn cbx-btn btn-lg btn-block" onClick={()=>setShopid(shop.shop_id)} type="button" 
                            data-bs-toggle="modal" data-bs-target="#exampleModal">ADD COUPON</a>
                        </div>
                    </div>
                            </div>)}

                            

<div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Add New Coupon/Deal</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <form onSubmit={csubmitForm} method='post' encType='multipart/form-data'>
            <div className='form-group'>
                <label>Coupon Title</label>
                <input type="text" className="form-control" name="coupon_title" onChange={handleChange} required/>                              
            </div>
            <div className='row'>
            <div className='form-group col-md-4'>
                <label>Coupon Code</label>
                <input type="text" className="form-control" name="coupon_code" onChange={handleChange} required/>                              
            </div>
            <div className='form-group col-md-4'>
                <label>Discount Type</label>
                <select className='form-control' onChange={handleChange} name='disc_type'>
                    <option value="Amount">Amount</option>
                    <option value="Percentage">Percentage</option>
                </select>                        
            </div>
            <div className='form-group col-md-4'>
                <label>Use Type</label>
                <select className='form-control' onChange={handleChange} name='max_use'>
                    <option value="Once">Once</option>
                    <option value="Multiple">Multiple</option>
                </select>                        
            </div>
            <div className='form-group  col-md-4'>
                <label>Discount Value</label>
                <input type="text" className="form-control" name="dis_amt" onChange={handleChange} required/>                              
            </div>
            <div className='form-group  col-md-4'>
                <label>Min Order Value</label>
                <input type="text" className="form-control" name="min_order" onChange={handleChange} required/>                              
            </div>
            <div className='form-group  col-md-4'>
                <label>Max Discount</label>
                <input type="text" className="form-control" name="max_disc" onChange={handleChange}/>                              
            </div>
            <div className='form-group col-md-6'>
                <label>Coupon Image</label>
                <input type="file"  onChange={handleimg2}  className="form-control" name="coupon_img"/>
                <img src={inputField.coupon_img} className='img img-responsive'/>
            </div>
            <div className='form-group  col-md-6'>
                <label>Expiry Date</label>
                <input type="date" className="form-control" name="coupon_exp" onChange={handleChange} required/>                              
            </div>
            </div>
            <div className='form-group'>
                <label>Coupon Description</label>
                <textarea rows="2" name="coupon_desc"  onChange={handleChange}  className="form-control"></textarea>
            </div>
            <button type="submit" className="btn btn-warning btn-lg">ADD COUPON</button>
            <button type="button" className="btn btn-secondary btn-lg float-right" data-bs-dismiss="modal">Close</button>
        </form>
      </div>
    </div>
  </div>
</div>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
      </>
    );
  }
  
  export default Dashboard;


  