import React from 'react';
import {Link,useNavigate  } from 'react-router-dom';
import axios from 'axios';
import { useState,useEffect } from "react";
import { Helmet } from 'react-helmet';


function Login(navigation,props) {
  const navigate = useNavigate();
  const [inputField , setInputField] = useState({username:'',password:''});
  const [isBackButtonClicked, setBackbuttonPress] = useState(false)

   
  useEffect(() => {

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);

    //logic for showing popup warning on page refresh
    window.onbeforeunload = function () {

      return "Data will be lost if you leave the page, are you sure?";
    };
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBackButtonEvent = (e) => {
      //alert("adsf")
    e.preventDefault();
    if (!isBackButtonClicked) {

      if (window.confirm("Do you want to go to Main Website")) {
        setBackbuttonPress(true)
        window.location.replace('https://swastikamplemcx.com')
      } 
    }
}
  
  

  const handleChange = (e) =>{
    const value = e.target.value;
    setInputField({
        ...inputField,
        [e.target.name]: value
    });
  }

  const handleSubmit = (e) =>{e.preventDefault();
    axios.post('https://offerspick.com/admin/Welcome/login_api', {username:inputField.username,password:inputField.password})
        .then((res) => {
        //console.log(res.data);return false;
        
        if(res.data.status===1){
            localStorage.setItem('token',1);
            localStorage.setItem('isLoggedIn',true);
            localStorage.setItem('shop',true);
            localStorage.setItem('userid',res.data.userid);
            localStorage.setItem('name',res.data.name);
            navigate("/dashboard");
        }else{
            alert(res.data.msg);
        }
        //if(res.status==1){this.componentDidMount();}
    }).catch((err) => alert("Couldnot reach to server"));    
  }


  return (
    <section className="contact-wrapper">
            <div className="contact-wrapper-inner">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="contact-form-wrapper">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="contact-title">
                                            <h6>Sign In Your Account</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-xs-12">
                                        <div className="post-comment-form-group">
                                        <form method='post'  onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <input type="text" name="username"  onChange={handleChange}  placeholder="Mobile*" className="form-control" required aria-required="true"/>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <input type="password" name="password"  onChange={handleChange}  placeholder="Password*" className="form-control" required aria-required="true"/>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <button type="submit" className="btn btn-block btn-brand btn-lg">LOGIN</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <a href="#" className="pull-right">Forgot Password?</a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="askAccount-wrapper text-center">
                                            <Link to="/register">Haven't An Account?</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  );
}

export default Login;
