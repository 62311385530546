
import React,{Component} from 'react';
import axios from 'axios';
import { useState,useEffect } from "react";
import {Link} from 'react-router-dom';

function Wishlist() {

    const [offers,setOffers]=useState([]);
    const [qrimg,setQrimg]=useState();

    

    useEffect(() => {
        var userid= localStorage.getItem('userid');
        fetch('https://offerspick.com/admin/welcome/wishlist_api/'+userid).then((response)=>response.json())
            .then((responseJson)=>{
                console.log(responseJson)
                setOffers(responseJson);
            }).catch((error)=>{
              console.log(error);
            });
      }, []);

    return (
      <>
      <section className="dashboard-wrapper single-coupon-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 no-padding-left-right">
                        <div className="dashboard-title text-center">
                            <h6>Welcome {localStorage.getItem('name')}</h6>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-2">
                        <div className="dashboard-nav">
                            <ul>
                                <li className="active"><Link to="/mywishlist"><i className='fa fa-tag'></i> My Offers</Link></li>
                                <li><Link to="/claimed"><i className='fa fa-tag'></i> Claimed Offers</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-10">
                    <div className="dashboard-wrap" style={{padding:"20px"}}>
                    <div class="row">
                            {offers.map(item=>
                            <div class="col-md-3 col-sm-6 col-xs-12">
                                <div  className="item deal-item">
                    <div  className="deal-thumb">
                        <img src={'https://offerspick.com/admin/assets/img/'+item.offer_img} alt=""  className="img-responsive" />
                        <div  className="deal-badge">
                           
                        {item.offer_type=='Amount' && '₹'+item.offer_discount+' off'}
                                        {item.offer_type!='Amount' && item.offer_discount+'% off'}
                        </div>

                    </div>
                    <div className="deal-content">
                          <h6><a href="#">{item.offer_title} </a></h6>
                          {item.offer_type=='Amount' &&
                          <p><b className='text-danger'>₹{item.offer_discount}</b> off {item.offer_mino!='' && ' Min Order ₹'+item.offer_mino}</p>}
                          {item.offer_type!='Amount' &&
                          <p><b className='text-danger'>{item.offer_discount}%</b> off {item.offer_maxd!='' && ' upto ₹'+item.offer_maxd} {item.offer_mino!='' && ' Min Order ₹'+item.offer_mino}</p>}
                          <small><b className='text-primary'>{item.shop_name}</b>
                          <span className='badge bg-success float-end'>{item.cat_name}</span><br/>
                          <i className="fa fa-map-marker"></i> {item.shop_add}</small>
                          <div className="deal-content-bottom">
                              <p><i class="fa fa-clock-o"></i> {item.offer_exp}</p>
                              <a data-bs-toggle="modal" data-bs-target="#qrModal"
                              onClick={()=>setQrimg(item.wc_cqr)} href="javascript: void(0)"  class="btn btn-lg">VIEW QR</a>
                          </div>
                      </div>
                </div>
                            </div>)}
                          </div>
                          </div>
                          </div>
                    </div>
                </div>
        </section>
        <div className="modal fade" id="qrModal" tabindex="-1" aria-labelledby="qrModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-sm">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="qrModalLabel">Scan this at shop</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body post-comment-form-group">
            <img src={'https://offerspick.com/admin/assets/ucoupons/'+qrimg} alt="" class="img-responsive" />                                        
            </div>
            </div>
        </div>
        </div>          
      </>
    );
  }
  
  export default Wishlist;


  