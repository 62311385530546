import { Link } from 'react-router-dom';
import React,{Component} from 'react';
import axios from 'axios';
import { useState,useEffect} from"react";

function Category() {

  const [cats,setCats]=useState([]);

  useEffect(()=>{
    fetch('https://offerspick.com/admin/welcome/categoryapi').then((response)=>response.json())
    .then((responseJson)=>{
      setCats(responseJson);
    }).catch((error)=>{
      console.log(error);
    });
    }, []);

    return (
      <div>
           <div id="page-content-wrap">
            <div className="blog-page-content-wrap section-padding">
                <div className="container">
                    <div className="blog-item-wrapper">
                        <div className="single-blog-item">
                            <div className="row g-5">
                            {cats.map(x=>(
                                <div className="col-md-3">
                                    <div className="blog-thumb">
                                        <Link to={'/coupons/'+x.cat_slug}>
                                            <img src={'https://offerspick.com/admin/assets/img/'+x.cat_img} alt={x.cat_name} className="img-responsive img-rounded" />
                                        </Link>
                                    </div>
                                    <div className="blog-content">
                                        <h4 className="text-center"><Link to={'/coupons/'+x.cat_slug}>{x.cat_name}</Link></h4>                                        
                                    </div>
                                </div>))}
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
      </div>
    );
  }
  
  export default Category;


  