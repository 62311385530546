
import React,{useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';


function Home() {

  const [offers,setOffers]=useState([]);
  const [fdeals,setFdeals]=useState([]);
  const [banner,setBanner]=useState([]);
  const [stores,setStores]=useState([]);
  const [cqr,setCqr]=useState();
  const [banner1,setBanner1]=useState('');
  const [banner2,setBanner2]=useState('');
  const [banner3,setBanner3]=useState('');
  const [spinner,setSpinner]=useState(true);

  useEffect(()=>{
    //window.scrollTo(0, 0);
    if(!localStorage.getItem('city_id')){
    var city= 11;
    }else{
    var city= localStorage.getItem('city_id');
    }
    fetch('https://offerspick.com/admin/welcome/bannerapi').then((response)=>response.json())
            .then((responseJson)=>{
              console.log(responseJson);  
              setBanner(responseJson.banner); 
              setBanner1(responseJson.admin.b1_img); 
              setBanner2(responseJson.admin.b2_img); 
              setBanner3(responseJson.admin.b3_img); 
              setSpinner(false)             
            }).catch((error)=>{
              console.log(error);
            });
    fetch('https://offerspick.com/admin/welcome/api1/'+city).then((response)=>response.json())
            .then((responseJson)=>{
               //console.log(responseJson);
               setOffers(responseJson);
               
            }).catch((error)=>{
              console.log(error)
            }); 
            fetch('https://offerspick.com/admin/welcome/fdeals_api/'+city).then((response)=>response.json())
            .then((responseJson)=>{
               console.log(responseJson);
               setFdeals(responseJson);
            }).catch((error)=>{
              console.log(error)
            });  
            axios.get('https://offerspick.com/admin/welcome/stores_api/'+city)
            .then((responseJson) => {
              //console.log(responseJson.data);
              setStores(responseJson.data);
        }).catch((err) => alert("Data Loading Error"));
   
            
            const script = document.createElement('script')
            script.src = 'assets/js/app.js' // path of the plugin called Slick Slider
            script.async = true // false not work too
            document.body.append(script);
    }, []);

    const generateQr=(id)=>{
        if(!localStorage.getItem('userid')){return false;}
        var userid= localStorage.getItem('userid');
        axios.post('https://offerspick.com/admin/Welcome/generate_qr/', {cid:id,user:userid})
            .then((res) => {
               setCqr(res.data.qrimg);
        }).catch((err) => console.log("Couldnot reach to server"));   
    }

  return (
    <>
    <div  className="deal-coupon-slider-wrapper">
                <div  className="row">
                    <div  className="col-md-12 col-sm-12">
                        <div id="deal-coupon-slider"  className="owl-carousel owl-theme slider-list">
                            {banner.map(bn=>
                            <div  className="item">
                                <div style={{backgroundImage: "url(https://offerspick.com/admin/assets/img/" + bn.banner_img + ")"}}
                                className="hero-area hero-slider-one bg--img">
                                     <div  className="row">
                                        <div  className="col-sm-3 col-sm-offset-1">
                                            <div  className="offer owl-fadeInLeft">
                                                <img style={{opacity:0}} src="assets/images/offer.png" alt="offer icon"  className="img-responsive" />
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div  className="row">
                                        <div  className="col-sm-8">
                                            <div  className="offer-content owl-fadeIn">
                                                <h6><a href="#">{bn.banner_tittle}</a></h6>
                                                <p><i  className="fa fa-clock-o"></i>3 days, 8h Remaining</p> 
                                            </div>
                                        </div>
                                    </div>*/}
                                </div>
                            </div>)}
                        </div>
                    </div>
            </div>
        </div>
     <div  className="new-deal-wrapper">

<div  className="container-fluid">

    <div  className="row align-items-center section-heading-wrapper">

        <div  className="col-sm-10 col-xs-12">
            <h3  className="section-heading">New Offers</h3>
        </div>
        <div  className="col-sm-2 col-xs-12">
            <div  className="view-all">
                <Link to="/coupons/new">View All</Link>
            </div>
        </div>

    </div>

    <div  className="row">
        <div  className="col-md-12">
            <div id="new-deal-carousel"  className="owl-carousel owl-theme new-deal-list">
            {offers.map(item=>
                <div  className="item deal-item">
                    <div  className="deal-thumb">
                        <img src={'https://offerspick.com/admin/assets/img/'+item.offer_img} alt=""  className="img-responsive" />
                        <div  className="deal-badge">
                           
                        {item.offer_type=='Amount' && '₹'+item.offer_discount+' off'}
                                        {item.offer_type!='Amount' && item.offer_discount+'% off'}
                        </div>

                    </div>
                    <div className="deal-content">
                          <h6><a href="#">{item.offer_title} </a></h6>
                          {item.offer_type=='Amount' &&
                          <p><b className='text-danger'>₹{item.offer_discount}</b> off {item.offer_mino!='' && ' Min Order ₹'+item.offer_mino}</p>}
                          {item.offer_type!='Amount' &&
                          <p><b className='text-danger'>{item.offer_discount}%</b> off {item.offer_maxd!='' && ' upto ₹'+item.offer_maxd} {item.offer_mino!='' && ' Min Order ₹'+item.offer_mino}</p>}
                          <small><b className='text-primary'>{item.shop_name}</b>
                          <span className='badge bg-success float-end'>{item.cat_name}</span><br/>
                          <i className="fa fa-map-marker"></i> {item.shop_add}</small>
                          <div className="deal-content-bottom">
                              <p className='text-dark'><i className="fa fa-tag"></i> {item.total_claimed} Claimed</p>
                              <a onClick={()=>generateQr(item.offer_id)} data-bs-toggle="modal" data-bs-target={!localStorage.getItem('user')?'#loginModal':'#qrModal'}
                              href="javascript: void(0)" className="btn btn-lg">GET QR</a>
                          </div>
                      </div>
                </div>)}
                </div>
                </div></div></div></div>

                <div className="popular-deal-wrapper">

<div className="container-fluid">

    <div className="row align-items-center section-heading-wrapper">

        <div className="col-sm-10 col-xs-12">
            <h3 className="section-heading">Popular Deal</h3>
        </div>
        <div className="col-sm-2 col-xs-12">
            <div className="view-all">
                <Link to="/coupons/featured">View All</Link>
            </div>
        </div>

    </div>

    <div className="row">
        <div className="col-md-12">

            <div id="popular-deal-carousel" className="owl-carousel owl-theme new-deal-list">
            {fdeals.map(item=>
                <div  className="item deal-item">
                    <div  className="deal-thumb">
                        <img src={'https://offerspick.com/admin/assets/img/'+item.offer_img} alt=""  className="img-responsive" />
                        <div  className="deal-badge">
                           
                        {item.offer_type=='Amount' && '₹'+item.offer_discount+' off'}
                                        {item.offer_type!='Amount' && item.offer_discount+'% off'}
                        </div>

                    </div>
                    <div className="deal-content">
                          <h6><a href="#">{item.offer_title} </a></h6>
                          {item.offer_type=='Amount' &&
                          <p><b className='text-danger'>₹{item.offer_discount}</b> off {item.offer_mino!='' && ' Min Order ₹'+item.offer_mino}</p>}
                          {item.offer_type!='Amount' &&
                          <p><b className='text-danger'>{item.offer_discount}%</b> off {item.offer_maxd!='' && ' upto ₹'+item.offer_maxd} {item.offer_mino!='' && ' Min Order ₹'+item.offer_mino}</p>}
                          <small><b className='text-primary'>{item.shop_name}</b>
                          <span className='badge badge-dark'>{item.cat_name}</span><br/>
                          <i className="fa fa-map-marker"></i> {item.shop_add}</small>
                          <div className="deal-content-bottom">
                              <p className='text-dark'><i className="fa fa-tag"></i> {item.total_claimed} Claimed</p>
                              <a onClick={()=>generateQr(item.offer_id)} data-bs-toggle="modal" data-bs-target={!localStorage.getItem('user')?'#loginModal':'#qrModal'}
                              href="javascript: void(0)" className="btn btn-lg">GET QR</a>
                          </div>
                      </div>
                </div>)}
            </div>
        </div>
    </div>

</div>
</div>
              

<section className="trending-stores-wrapper stores-padding">
<div className="container-fluid">
    <div className="row">
        <div className="col-md-12">
            <h2 className="section-heading">All Stores</h2>
        </div>
    </div>
    <div className="row">{stores.map(item=>
        <div className="col-md-3 col-sm-6 col-xs-12 no-padding-left-right">
            <div className="single-store">
                <img src={'https://offerspick.com/admin/assets/img/'+item.shop_img}  alt="Store" className="img-responsive" />
                <div className="store-btn-wrapper">
                    <Link to={"/store/"+item.shop_slug} className="cbx-btn btn-sm">Visit Store</Link>
                </div>
            </div>
        </div>)}
    </div>
</div>
</section>
<div className="modal fade in"  id="qrModal" tabindex="-1" aria-labelledby="qrModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-sm">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="qrModalLabel">OFFER QR CODE</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body post-comment-form-group">
            <img src={cqr} alt=""  className="img-responsive" />
            </div>
            </div>
        </div>
        </div>
        </>
  );
}

export default Home;
