import React from 'react';
import axios from 'axios';
import { useState,useEffect } from "react";
import { useParams,Link, useNavigate} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import OtpInput from 'react-otp-input';

function Register() {

    const navigate = useNavigate();
    const [otp, setOtp] = useState('');
    const [cities, setCities] = useState([]);
    const [loader, setLoader] = useState(false);
    const [inputField , setInputField] = useState({name:'',phone:'',email:'',password:'',cpassword:'',userotp:'',aadhar:'',pan:'',
city:'',pincode:'',aadhar_img:'',pan_img:''});

    const handleChange = (e) =>{
        const value = e.target.value;
        setInputField({
            ...inputField,
            [e.target.name]: value
        });
    }

      
    
    useEffect(()=>{
        fetch('https://offerspick.com/admin/welcome/cities_api').then((response)=>response.json())
            .then((responseJson)=>{
              console.log(responseJson);  
              setCities(responseJson);
              
            }).catch((error)=>{
              console.log(error);
            });
    },[]);
    
    const handleFile=(e)=>{
        console.log(e.target.files[0]);
        //this.setState({assoc_img:e.target.files[0]})
        setInputField({
            ...inputField,
            aadhar_img: e.target.files[0]
        });
    }

    const handleFile2=(e)=>{
        console.log(e.target.files[0]);
        //this.setState({assoc_img:e.target.files[0]})
        setInputField({
            ...inputField,
            pan_img: e.target.files[0]
        });
    }

    const submitForm=(event)=>{event.preventDefault();        
        var formData = new FormData();
        formData.append("name", inputField.name);
        formData.append("email", inputField.email);
        formData.append("phone", inputField.phone);
        formData.append("password", inputField.password);
        formData.append("cpassword", inputField.cpassword);
        formData.append("city", inputField.city);
        const config = {
            headers: {'content-type': 'multipart/form-data'},
          };      
        axios.post('https://offerspick.com/admin/Welcome/register_api', formData,config)
            .then((res) => {
                console.log(res.data);
                
                if(res.data.status==0){
                    setInputField({ ...inputField,userotp: res.data.otp});
                }
        }).catch((err) => alert("File Upload Error"));    
    }

    const formSubmit = param => e => {e.preventDefault();    
        setLoader(true)    
        if(inputField.password!=inputField.cpassword){alert("Both Password must match");return false;}
        if(inputField.password.length<6){alert("Both must be atleast 6 char long");return false;}
        if(inputField.name=='' || inputField.email=='' || inputField.phone=='' || inputField.city==''){
            alert("Please Provide all details");return false;
        }
        axios.post('https://offerspick.com/admin/Welcome/register_api', {name:inputField.name,mobile:inputField.phone,
        email:inputField.email,password:inputField.password,cpassword:inputField.cpassword,city:inputField.city})
            .then((res) => {
            console.log(res.data);
            //return false;
            setLoader(false)
            alert(res.data.msg);
            if(res.data.status==0){
                setInputField({
                    ...inputField,userotp: res.data.otp
                });
            }
        }).catch((err) => alert("Error start trading"));    
    }

    const otpSubmit = param => e => {e.preventDefault();        
        if(otp!=inputField.userotp){alert("Incorrect OTP");return false;}
        setLoader(true)
        var formData = new FormData();
        formData.append("name", inputField.name);
        formData.append("email", inputField.email);
        formData.append("mobile", inputField.phone);
        formData.append("password", inputField.password);
        formData.append("cpassword", inputField.cpassword);
        formData.append("city", inputField.city);
        formData.append("pincode", inputField.pincode);
        formData.append("aadhar", inputField.aadhar);
        formData.append("pan", inputField.pan);
        formData.append("aadhar_img", inputField.aadhar_img);
        formData.append("pan_img", inputField.pan_img);
        const config = {
            headers: {
              'content-type': 'multipart/form-data',
            },
          };
      
        axios.post('https://offerspick.com/admin/Welcome/register_api2', formData,config)
            .then((res) => {
                setLoader(false)
                console.log(res.data);//return false;
                alert(res.data.msg);
                if(res.data.status==0){
                    navigate("/login");
                }
        }).catch((err) => alert("File Upload Error"));       
    }

    return (
        <>
        <section className="contact-wrapper">
            <div className="contact-wrapper-inner">
                <div className="container">
                    <div className="row justify-content-center g-5">
                        <div className="col-md-8">
                            <div className="contact-form-wrapper">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="contact-title">
                                            <h6>Create Your Accounts</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="post-comment-form-group">
                                        {inputField.userotp=='' &&
                                            <form action="#" method="post">
                                            <div className="row">
                                                    <div className="col-md-12">
                                            <select class="form-control" name="city" onChange={handleChange} value={inputField.city}
                                             onChange={handleChange}>
                                                <option value="">Select City</option>
                                            {cities.map(c=>(   <option value={c.citi_id}>{c.city_name}</option>))}
                                            </select>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <input onChange={handleChange} type="text" value={inputField.name}  name="name" required placeholder="Name*" className="form-control" required aria-required="true"/>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <input onChange={handleChange} type="text" name="phone" value={inputField.phone}  required  placeholder="Mobile*" className="form-control" required aria-required="true"/>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <input onChange={handleChange} type="email" name="email" value={inputField.email} required  placeholder="Email*" className="form-control" required aria-required="true"/>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <input onChange={handleChange} type="password" name="password" value={inputField.password} required  placeholder="Create Password*" className="form-control" required aria-required="true"/>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <input type="password" onChange={handleChange} name="cpassword" value={inputField.cpassword} required  placeholder="Confirm Password*" className="form-control" required aria-required="true"/>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <button  type="button" onClick={formSubmit()}  
                                                        className="btn btn-block btn-brand btn-lg">REGISTER</button>
                                                    </div>
                                                </div>
                                            </form>}
                                            {inputField.userotp!='' &&
    <div className='text-center'><br/>
        <h4 className='text-warning'>Verify Your OTP</h4><hr/>
    <OtpInput inputStyle={{width:'15%',height:'40px'}} containerStyle={{width:'100%'}}
      value={otp}
      onChange={setOtp}
      numInputs={6}
      renderSeparator={<span>-</span>}
      renderInput={(props) => <input {...props} />}
    /><br/>
    {otp.length==6 &&
       <button type='button' onClick={otpSubmit()} className="btn btn-lg btn-warning btn-block">VERIFY OTP</button>
        }
    </div>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="login-wrapper text-center">
                                            <p><span>or</span> Connect with</p>
                                            <div className="login-with-social">
                                                <ul>
                                                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                                    <li><a href="#"><i className="fab fa-google"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="askAccount-wrapper text-center">
                                            <Link href="/login">Have An Account?</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
  }
  
  export default Register;


  